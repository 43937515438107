// @ngInject
function acpAuthManager(
  ACP_STORAGE_KEYS,
  acpBrandingModel,
  acpCoreDispatcher,
  $state,
  nsUtil,
  acpAuthBlocksModel,
  acpBiometricsLoginDomainClient,
  acpInterstitialDomainClient,
  acpAuthModel,
  acpOobaModel,
  acpFrontendConfigModel,
  acpApiBrandingDomainClient,
  $log,
  $window,
  nsStorage,
  acpAuthClient,
  nsPermissions,
  acpDeviceService,
  acpPushNotificationPlugin,
  acpLogActionClient,
  acpTermsModel,
  acpApptentivePlugin
) {
  var def = {};

  def.autoEnrollAndroidDevice = async function () {
    let widget;
    const isEulaEnabled = acpFrontendConfigModel.get(
      acpFrontendConfigModel.keys.IS_EULA_ENABLED
    );
    if (!isEulaEnabled) {
      // make interstitial call to identify next interstitial
      widget = await acpInterstitialDomainClient.get();
    }
    // Accept Anytime alerts if next interstitial is EULA
    ((widget && widget.id === 'EndUserLicenseAgreementInterstitial') ||
      isEulaEnabled) &&
      (await acpTermsModel.acceptTerms('alerts_v2'));
    const isUpdatedAlertTermsAccepted = await nsPermissions.requestPermission(
      'isUpdatedAlertTermsAccepted'
    );
    // auto enroll android device if updated terms are accepted
    if (isUpdatedAlertTermsAccepted) {
      acpPushNotificationPlugin.syncDeviceNotification(false);
    }
  };

  def.pushNotificationHandler = async function () {
    const info = await acpAuthClient.info();
    // check ooba first because of anytime alert API
    if (info.ooba) {
      const isPushNotificationEnabled = await nsPermissions.requestPermission(
        'isPushNotificationEnabled'
      );
      if (isPushNotificationEnabled) {
        if (acpPushNotificationPlugin.savedToken) {
          // sync alert API with device notification status
          acpPushNotificationPlugin.syncDeviceNotification(true);
        } else if (acpDeviceService.isAndroid()) {
          await def.autoEnrollAndroidDevice();
        }
      }
    }
  };

  function loginUpdated(data) {
    acpFrontendConfigModel.load().then(function () {
      if (
        data.type === 'authed.password' ||
        data.type === 'authed.password.blocks.completed'
      ) {
        return acpBrandingModel.get().finally(function () {
          handlePostLogin();
        });
      } else if (data.type === 'authed.password.blocks') {
        acpAuthBlocksModel.set(data.blocks);
        def.nextBlock();
      }
    });
  }

  function onLoggedOut(data) {
    var logoutReason =
      data && data.type === 'session_timeout' ? 'session-timeout' : null;
    var newHref = $state.href('login.main', {
      reason: logoutReason
    });

    if (newHref[0] === '#') {
      // We need to reload the whole page, so if we are hash-routing, we will force a reload since changing the hash won't do it
      $window.location.hash = newHref;
      $window.location.reload();
    } else {
      // A simple assign will change the URL and load a new page when using HTML5 routing
      $window.location.assign(newHref);
    }
  }

  async function handlePostLogin() {
    // After login handle push notification subscription status or
    // auto enrolled android device for push notification
    try {
      await def.pushNotificationHandler();
    } catch (error) {
      $log.error('acpAuthManager.pushNotificationHandler()', error);
    }

    try {
      acpApptentivePlugin.sendEvent('Login_home');
    } catch (error) {
      $log.error('acpAuthManager.acpApptentivePlugin()', error);
    }

    acpLogActionClient.logUserAction({
      action: 'entering_oac'
    });
    var postLoginRedirect = acpAuthModel.getPostLoginRedirect();
    let branding, variantID, isSubVariant;
    if (postLoginRedirect) {
      branding = await acpApiBrandingDomainClient.getApiBranding();
      variantID = branding.brand.variant_id.replace('variant://', '');
      isSubVariant = variantID.includes('/');
    }
    // keep any parameter that has been received
    var params = nsStorage.session(
      ACP_STORAGE_KEYS.ACP_AUTH_MODEL_POST_LOGIN_REDIRECT_PARAMETERS
    );
    nsStorage.removeSessionItem(
      ACP_STORAGE_KEYS.ACP_AUTH_MODEL_POST_LOGIN_REDIRECT_PARAMETERS
    );

    if (
      nsUtil.isDefined(postLoginRedirect) &&
      postLoginRedirect !== null &&
      // postLoginRedirect comes back as 'authenticated' after first attempt, need to check this here
      !nsStorage.session(ACP_STORAGE_KEYS.ACP_AUTH_MODEL_BIOMETRICS_ENROLLMENT)
    ) {
      acpAuthModel.clearPostLoginRedirect();
      // We have a route
      if (acpAuthModel.isAngularState(postLoginRedirect) && !isSubVariant) {
        $state.go(postLoginRedirect, params);
      } else {
        if (isSubVariant) {
          $window.location.assign(
            postLoginRedirect.split('.')[0] + `?tenant=${variantID}`
          );
          return;
        } else {
          // We have no choice but to redirect them to the dark side again
          $window.location.assign(postLoginRedirect);
          return;
        }
      }
    } else {
      if (
        acpBiometricsLoginDomainClient.needsEnrollment() &&
        acpAuthModel.hasCredentialCache()
      ) {
        $state.go('login.biometrics');
      } else {
        // To show interstitial on a separate page and not dashboard
        acpInterstitialDomainClient
          .get()
          .then(function (interstitials) {
            if (interstitials) {
              $state.go('interstitials');
            } else {
              $state.go('dashboard');
            }
          })
          .catch(function () {
            $state.go('dashboard');
          });
      }
    }

    acpCoreDispatcher.login.loggedIn.emit();
  }

  function genericBlockClearedHandler(block) {
    return function () {
      acpAuthBlocksModel.remove(block);
      def.nextBlock();
    };
  }

  def.nextBlock = function () {
    var routes;
    routes = {
      change_password: 'login.reset-password',
      set_security_question: 'login.set-security-question',
      answer_questions: 'login.idq',
      ooba_required: 'login.ooba'
    };

    if (acpAuthBlocksModel.hasUnknown()) {
      $log.error(
        'acpAuthManager.nextBlock() unknown block',
        acpAuthBlocksModel.current()
      );
      $state.go('login.call-cs');
    } else if (acpAuthBlocksModel.has()) {
      if (acpAuthBlocksModel.current()[0] === 'activate_account') {
        $window.location.assign('/account/activate/cardless-handoff');
      } else {
        $state.go(routes[acpAuthBlocksModel.current()[0]]);
      }
    } else {
      // Signal blocks are completed.

      // Completing OOBA in Activation kicks the user into ACP in the middle
      // of the feature enrollment flow. Real logins from Activation are
      // handled in acpActivationService
      if (!$state.is('public.activate')) {
        acpCoreDispatcher.authBlocks.completed.emit();
      }
    }
  };

  function blockFailed() {
    $state.go('login.call-cs');
  }

  def.listen = function () {
    // login changed
    acpCoreDispatcher.login.updated.onValue(loginUpdated);

    // logged out
    acpCoreDispatcher.login.loggedOut.onValue(onLoggedOut);

    // blocks completed
    acpCoreDispatcher.authBlocks.completed.onValue(function () {
      acpCoreDispatcher.login.updated.emit({
        type: 'authed.password.blocks.completed'
      });
    });

    // block success
    acpCoreDispatcher.setPassword.success.onValue(
      genericBlockClearedHandler('change_password')
    );
    acpCoreDispatcher.idq.success.onValue(
      genericBlockClearedHandler('answer_questions')
    );
    acpCoreDispatcher.setSecurityQuestion.success.onValue(
      genericBlockClearedHandler('set_security_question')
    );
    acpCoreDispatcher.setOoba.success.onValue(
      genericBlockClearedHandler('ooba_required')
    );

    // block fails
    acpCoreDispatcher.idq.failed.onValue(blockFailed);
  };

  return def;
}

export default acpAuthManager;
