//@ngInject
function acpAuthExpiredInterceptor($q, acpAuthModel) {
  return {
    responseError: function(error) {
      if (error.error === 'auth.expired') {
        var authInfo = acpAuthModel.info.getValue();
        if (authInfo && authInfo.password) {
          // This makes sure the acpSessionTimer gets reset
          acpAuthModel.info.set(acpAuthModel.defaultInfo);

          acpAuthModel.sessionTimeout();
        }
      }

      return $q.reject(error);
    }
  };
}

export default acpAuthExpiredInterceptor;
