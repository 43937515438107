// @ngInject
function acpAppInitializer(
  $state,
  $urlRouter,
  nsUtil,
  $log,
  $q,
  $window,
  $location,
  acpStatusClient,
  webapiResource,
  acpAuthModel,
  acpToast,
  acpDialog,
  acpCoreDispatcher,
  nsConfig,
  acpAuthManager,
  acpAuthBlocksModel,
  acpDeviceService,
  acpAuthEventService,
  acpBrandingModel,
  content,
  acpAnalytics,
  acpSessionTimer,
  acpBackButton,
  acpInterstitialDomainModel,
  acpSimpleAccountModel,
  acpConfigurationService,
  acpStateService,
  acpScrollTop,
  acpThirdPartyIdentity,
  acpInPageFlowService,
  acpFrontendConfigModel,
  acpLogException,
  acpContentFilter,
  acpReloadPageService,
  acpRouteInitializationService,
  acpAttributionService,
  acpAnalyticsApiDomainModel,
  acpPushNotificationPlugin,
  acpAdaChatbotPlugin
) {
  var def = {};

  def.checkStatus = function () {
    acpStatusClient
      .check()
      .then(function gotStatus(result) {
        if (result === true) {
          return acpFrontendConfigModel.load().then(
            function () {
              acpAnalytics.installAdobeLaunch(
                acpFrontendConfigModel.get('adobe.launch.url')
              );
              return acpAuthModel.getInfo();
            },
            function () {
              return $q.reject('config_failed');
            }
          );
        } else {
          def.apiWentBoom();
          return $q.reject('status_failed');
        }
      })
      .then(gotSessionInfo, failedSessionInfo);
  };

  def.apiWentBoom = function () {
    $log.error('apiWentBoom');
    acpCoreDispatcher.api.state.set('offline');
  };

  /* global device:false */
  def.setupWebapiResource = function () {
    webapiResource.defaults.headers.common['X-NS-Client'] =
      'app=' +
      nsConfig.get('appName') +
      '; ' +
      'platform=' +
      nsConfig.get('platform') +
      '; ' +
      'platformType=' +
      nsConfig.get('platformType') +
      '; ' +
      'brand=' +
      nsConfig.get('brand') +
      '; ' +
      'version=' +
      nsConfig.get('version');

    webapiResource.defaults.headers.common[
      'X-NS-Variant'
    ] = `variant://${nsConfig.get('variant')}`;

    if (acpDeviceService.isCordova()) {
      webapiResource.defaults.headers.common['X-NS-DeviceID'] = device.uuid;
    }

    webapiResource.defaults.interceptors.push(
      'acpApiResourceTimeoutInterceptor',
      'acpAuthExpiredInterceptor'
    );
    webapiResource.setUrl('/webapi');
  };

  def.listen = function () {
    // We should listen for exceptions first so we can capture them.
    acpLogException.listen();
    acpSessionTimer.listen();
    acpAuthModel.listen();
    acpToast.listen();
    acpDialog.listen();
    acpCoreDispatcher.listen();
    acpAuthManager.listen();
    acpAuthEventService.listen();
    acpInterstitialDomainModel.listen();
    acpScrollTop.listen();
    acpInPageFlowService.listen();
    acpContentFilter.listen();
    acpAttributionService.listen();
    acpAnalyticsApiDomainModel.listen();
    acpPushNotificationPlugin.listen();
  };

  def.setStatusBarColor = function () {
    if (acpDeviceService.isCordova() && $window.StatusBar) {
      if (nsConfig.get('status_bar_background_color')) {
        $window.StatusBar.backgroundColorByHexString(
          nsConfig.get('status_bar_background_color')
        );
      }

      if (
        nsConfig.get('status_bar_foreground_color') &&
        nsConfig.get('status_bar_foreground_color') === 'dark'
      ) {
        $window.StatusBar.styleDefault();
      }
    }
  };

  function gotSessionInfo(info) {
    def.listen();
    // Listen for new location changes, but don't sync yet
    $urlRouter.listen();

    if (!info.hasSession) {
      //if there is no session, no need to get branding or account info, or to send them to an auth block
      determineProperRoute();
      return;
    }

    acpBrandingModel.get().then(() => {
      acpReloadPageService.reloadPageIfVariantMismatch(
        acpCoreDispatcher.account.branding.getValue().variant_id
      );
    });

    if (acpAuthBlocksModel.has()) {
      // If user is going to an unauthed page, don't sweat auth blocks
      if (acpStateService.isOnUnauthedState()) {
        acpSimpleAccountModel
          .get()
          .then(setHeapAnalyticsUser)
          .then(determineProperRoute, determineProperRoute);
      } else {
        // User has a token from activation, but they won't be able to clear
        // their auth blocks without password auth; send them to login to
        // get it

        // .card property is cvc authentication status --> the property name is transformed in acpAuthModel.getInfo
        if (info.card && !info.password) {
          $state.go('login');
        } else {
          // Set state to redirect to after blocks are taken care of
          var state = $state.stateForHref(location.pathname);
          if (state && state.state !== 'login') {
            acpAuthModel.setPostLoginRedirect(state.state);
          }

          //if the user has any auth blocks, redirect to those instead of the login page
          acpAuthManager.nextBlock();
        }
      }
    } else {
      acpSimpleAccountModel
        .get()
        .then(setHeapAnalyticsUser)
        .then(determineProperRoute, determineProperRoute);
    }
  }

  function determineProperRoute() {
    acpRouteInitializationService.initializeRoute().then(function () {
      $log.debug('Starting route has been initialized');
    });
  }

  function failedSessionInfo(err) {
    $urlRouter.listen();
    $urlRouter.sync();

    if (err && err.error === 'auth.expired') {
      def.listen();
      acpAuthModel.logout();
    } else {
      def.apiWentBoom();
      def.listen();
    }
  }

  function setHeapAnalyticsUser(profileResponse) {
    acpThirdPartyIdentity.setUser(profileResponse);
  }

  def.setupDefaults = function () {
    content.setBrand(acpConfigurationService.getBrand());
    content.setPlatformType(acpConfigurationService.getPlatformType());
    content.setEnvironment(acpConfigurationService.getEnvironment());
    content.setProgramType(acpConfigurationService.getProgramType());
    content.setSubaccountEnabled(false);
    content.setDeviceType(nsConfig.get('platform'));

    acpCoreDispatcher.account.programType.set(
      acpConfigurationService.getProgramType()
    );
  };

  /**
   * I check to see if there is a continue query string param and
   * I se the post login redirect url if it exists.
   */
  def.catchPostLoginRedirect = function () {
    var params = $location.search();

    if (nsUtil.isDefined(params.continue) && params.continue !== '') {
      acpAuthModel.setPostLoginRedirect(params.continue);
      $location.url($location.path()); // clear query string params
    }
  };

  def.start = function () {
    acpAnalytics.listen();
    def.setupWebapiResource();
    def.setupDefaults();
    def.setStatusBarColor();

    acpBackButton.listen();

    def.catchPostLoginRedirect();

    acpAuthEventService.sendTrackingForAllUsers();

    acpAdaChatbotPlugin.init();
  };

  return def;
}

export default acpAppInitializer;
