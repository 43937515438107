// @ngInject
function acpAppLoaderDirective(NS_ROUTER_EVENTS) {
  return {
    restrict: 'A',
    controller: /* @ngInject */ function($element, $scope) {
      var unregisterSuccess = $scope.$on(
        NS_ROUTER_EVENTS.ROUTE_CHANGE_SUCCESS,
        onStateCompleted
      );
      var unregisterError = $scope.$on(
        NS_ROUTER_EVENTS.ROUTE_CHANGE_ERROR,
        onStateCompleted
      );

      // Note, this only is triggered after the very first route change success
      function onStateCompleted() {
        $element.css({ display: 'none' });
        $element[0].dataset.initialLoad = 'true';
        unregisterSuccess();
        unregisterError();
      }
    }
  };
}

export default acpAppLoaderDirective;
