//@ngInject
function acpApiResourceTimeoutInterceptor($q, nsConfig, $log) {
  return {
    request: function(config) {
      if (config.timeout === undefined) {
        config.timeout = nsConfig.get('apiResourceTimeout');
      }
      return config;
    },

    response: function(data) {
      return data;
    },

    responseError: function(error) {
      if (
        error &&
        error._status === -1 &&
        error._errors.indexOf('server.unknown') > -1
      ) {
        $log.error(
          'Request timed out by acpApiResourceTimeoutInterceptor!',
          error
        );
        error._errors.push('request.timeout');
        error._server_errors.push('request.timeout');
      }
      return $q.reject(error);
    }
  };
}

export default acpApiResourceTimeoutInterceptor;
