import { acpLogOutDialogTemplate } from 'components/logout-dialog';
// @ngInject
function acpBackButton(
  $window,
  $document,
  $mdDialog,
  $mdBottomSheet,
  acpDeviceService,
  acpCoreDispatcher,
  $state,
  acpAuthModel,
  acpInterstitialDomainModel
) {
  var def = {};

  def.listen = function() {
    if (acpDeviceService.isAndroid()) {
      $window.document.addEventListener('backbutton', backButtonHandler);
    }

    acpCoreDispatcher.routing.logoutDialog.confirm.onValue(
      onLogoutDialogConfirm
    );
    acpCoreDispatcher.routing.logoutDialog.cancel.onValue(onLogoutDialogCancel);
  };

  function backButtonHandler(e) {
    e.preventDefault();

    acpCoreDispatcher.backButton.pressed.emit();

    if (acpCoreDispatcher.tours.current.getValue()) {
      return; // Already fired an event for tour to respond to
    }

    /* eslint-disable-next-line es5/no-es6-methods */
    if ($document.find('md-dialog').length > 0) {
      if (
        $state.current.name.indexOf('modal') > -1 &&
        /* eslint-disable-next-line es5/no-es6-methods */
        $document.find('md-dialog').length === 1
      ) {
        $window.navigator.app.backHistory();
      } else {
        $mdDialog.cancel();
      }
      return;
    }

    /* eslint-disable-next-line es5/no-es6-methods */
    if ($document.find('md-bottom-sheet').length > 0) {
      $mdBottomSheet.cancel();
      return;
    }

    var currentInterstitial = acpInterstitialDomainModel.interstitial.getValue();
    if (currentInterstitial) {
      acpInterstitialDomainModel.respond(currentInterstitial, 'dismissed');
      acpInterstitialDomainModel.interstitial.set(null);
      return;
    }

    if ($state.is('dashboard')) {
      acpCoreDispatcher.dialog.show.emit({
        name: 'logoutDialog',
        template: acpLogOutDialogTemplate,
        preload: import(/* webpackChunkName: "acp.component.logout-dialog" */ 'components/logout-dialog'),
        controller: 'acpLogoutDialogComponentCtrl'
      });
      return;
    }

    if ($state.is('login.main') || $state.is('pre-onboarding')) {
      $window.navigator.app.exitApp();
      return;
    }

    $window.navigator.app.backHistory();
  }

  function onLogoutDialogConfirm() {
    acpCoreDispatcher.dialog.hide.emit({
      name: 'logoutDialog'
    });

    acpAuthModel.logout();
  }

  function onLogoutDialogCancel() {
    acpCoreDispatcher.dialog.hide.emit({
      name: 'logoutDialog'
    });
  }

  return def;
}

export default acpBackButton;
