import acpAppInitializer from './acp-app-initializer';
import acpAuthManager from './acp-auth-manager';
import acpBackButton from './acp-back-button';
import acpAppLoaderDirective from './acp-app-loader-directive';
import ng from 'angular';
import acpComponentOobaDomain from 'components/ooba-domain';
import acpComponentAuthEventDomain from 'components/auth-event-domain';
import acpToast from 'shared/angularjs/services/acp-toast';
import acpDialog from 'shared/angularjs/services/acp-dialog';
import acpCore from 'core';
import acpRoutePageTitle from 'shared/angularjs/directives/route-page-title';
import acpPageH1 from 'shared/angularjs/directives/page-h1';
import acpPageMetaDescription from 'shared/angularjs/directives/page-meta-description';
import acpUpgradeLoggingModel from 'shared/angularjs/services/acp-upgrade-logging';
import acpApiResourceTimeoutInterceptor from './interceptors/acp-api-resource-timeout-interceptor';
import acpAuthExpiredInterceptor from './interceptors/acp-auth-expired-interceptor';
import { nsExternalAnchorConfig } from './acp-ns-external-anchor-config';
import { acpComponentPushNotificationDomain } from 'components/push-notification-domain';
import { acpComponentApptentiveDomain } from 'components/apptentive-domain';
import { acpComponentAdaChatbotDomain } from 'components/ada-chatbot';

const dependencies = [
  acpCore.name,
  acpToast.name,
  acpDialog.name,
  acpComponentOobaDomain.name,
  acpComponentAuthEventDomain.name,
  acpRoutePageTitle.name,
  acpPageH1.name,
  acpPageMetaDescription.name,
  acpUpgradeLoggingModel.name,
  acpComponentPushNotificationDomain.name,
  acpComponentApptentiveDomain.name,
  acpComponentAdaChatbotDomain.name,
  'netspend.legos'
];

const app = ng.module('acp.app', dependencies);

app
  .directive('acpAppLoader', acpAppLoaderDirective)
  .factory('acpBackButton', acpBackButton)
  .config(
    /*@ngInject*/ function (
      $compileProvider,
      $locationProvider,
      $mdThemingProvider,
      $animateProvider,
      $logProvider,
      nsConfigProvider,
      acpDeviceServiceProvider
    ) {
      const urlSafeRegex = /^\s*(https?|tel|mailto|itms-apps|itms|market|geo|sms|file|local):/;
      $compileProvider.aHrefSanitizationWhitelist(urlSafeRegex);
      $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);

      (window as any).nsConfig = nsConfigProvider;

      // Use push/pop state instead of # unless mobile
      if (acpDeviceServiceProvider.isServedFromHttp()) {
        // Use push/pop state instead of #
        $locationProvider.html5Mode({
          enabled: true,
          requireBase: true,
          rewriteLinks: true
        });
      } else {
        $locationProvider.html5Mode(false);
      }

      // We don't want to register theme because we primarily use our own
      // theming engine on top of material.
      $mdThemingProvider.theme(nsConfigProvider.get('legosTheme'));
      $mdThemingProvider.alwaysWatchTheme(true);
      $logProvider.debugEnabled(!nsConfigProvider.get('isDist'));
    }
  )
  .config(nsExternalAnchorConfig)
  .factory('acpAuthManager', acpAuthManager)
  .factory('acpAppInitializer', acpAppInitializer)
  .factory('acpApiResourceTimeoutInterceptor', acpApiResourceTimeoutInterceptor)
  .factory('acpAuthExpiredInterceptor', acpAuthExpiredInterceptor);

/**
 * Load all adapters to bootstrap enough information
 * required to start the application. This includes:
 * - registering components with the lazyLoadingProvider to
 * define the JavaScript module and css required
 * - registering any new routes with the routerProvider
 * - registering any new sections with the layoutProvider
 */

// @ts-ignore: this file is generated and exists at build time but not in editor so we ignore it
import sections from 'SECTIONS-MANIFEST';
sections(app);

// Finally run the app after everything else has finished.
app.run(
  /* @ngInject */ function (acpAppInitializer, $rootScope, $state) {
    acpAppInitializer.start();
    acpAppInitializer.checkStatus();

    //Here we return the current state to add it as a class name to the body in case we need it (i.e printed version of statements)
    $rootScope.activeRoute = function () {
      return $state.current ? $state.current.name : '';
    };
  }
);

export default app;

// @bazel-dep: components/external-link-dialog
